.data-list-item {
  line-height: 1.9;
}

.data-list-input {
  position: relative;
  &:after {
    content: "\25BC";
    color: black;
    position: absolute;
    right: 0.25em;
    top: 10%;
  }
}


