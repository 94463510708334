$mobileBreakpoint: 512px;

$mainControlsBackground: rgba(0, 0, 0, 0.75);

$tabListHeight: 2em;
$tabListMargin: 0.5em;
$tabBorder: grey 1px solid;
$panelPadding: 1em;

html {
  font-size: 16px;
}

body {
  margin: 0;
}

div {
  box-sizing: border-box;
}

h1 {
  font-size: 1.5em;
  margin: 0.25em 0;
}

h4 {
  display: inline;
  color: darkgray;
  margin-bottom: 0.5em;
  margin-block-end: 0.5em;
}

.App {
  font-family: sans-serif;
  height: 100vh;
  width: 100vw;
  background-color: #000;

  .vis-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .controls {
    position: absolute;
    margin-left: 1em;
    margin-top: 5em;
    z-index: 1;
    background: $mainControlsBackground;
    color: #fff;
    width: 350px;
    border-radius: 1em;
    touch-action: none; // Disable pinch to zoom on iOS devices
    overflow: auto;
    border: grey 1px solid;

    label {
      min-width: 6rem;
      line-height: 1.25;
      display: inline-block;
    }

    .performance-panel {
      label {
        min-width: 10rem;
      }
    }

    .about-panel {
      p:first-of-type {
        margin-top: 0;
      }
      .acknowledge-about-button {
        display: block;
        margin-left: auto;
        margin-right: auto;
      }
    }

    .controls-title-bar,
    .advanced-settings-title-bar {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .title {
        display: inline;
      }
    }

    .controls-title-bar {
      padding: 0.5em 1em;
    }

    input, button, select {
      font-size: 1em;
    }

    .selection-header {
      display: flex;
      justify-content: space-between;
      align-items: baseline;

      &>h3 {
        margin-bottom: 0;
      }
    }

    .search-section {
      display: flex;
      justify-content: space-between;
      justify-content: space-between;

      .data-list {
        flex-grow: 1;
        position: relative;

        .data-list-input{
          width: 100%;

          input {
            width: 100%;
          }
        }
      }

      button {
        margin-left: 1em;
      }
    }

    .selected-points-info {
      text-align: start;
      vertical-align: baseline;
      background: rgba(15, 15, 15, 0.9);
      padding: 0.5em;
      margin: 0.5em 0;
      border: 1px solid grey;
      max-height: 20vh;
      overflow: auto;
    }

    .resolution-scale-section {
      display: flex;

      >* {
        box-sizing: border-box;
      }

      label {
        flex-shrink: 0;
      }

      input, select {
        flex-shrink: 1;
        min-width: 0;
      }
    }
  }

  .error-message {
    color: white;
    font-size: 5em;
  }

  ul.react-tabs__tab-list {
    display: flex;
    padding: 0;
    margin-top: 0;
    margin-bottom: $tabListMargin;
    width: 100%;
  }

  .react-tabs__tab {
    list-style: none;
    display: inline-block;
    background: rgb(50, 50, 69);
    font-weight: bold;
    cursor: pointer;
    padding: 0 0.5em;
    height: $tabListHeight;
    line-height: $tabListHeight;
    border: $tabBorder;
    border-bottom: none;
    border-radius: 2px;
    flex-grow: 1;
    text-align: center;

    &:first-of-type {
      border-left: none;
    }

    &:last-of-type {
      border-right: none;
    }
  }

  .react-tabs__tab--selected {
    background: $mainControlsBackground;
  }

  .tab-panel.react-tabs__tab-panel--selected {
    padding: $panelPadding;
  }

  @media only screen and (max-width: $mobileBreakpoint) {
    .controls {
      position: fixed;
      margin-left: 5vw;
      width: 90vw;
      max-height: 90vh;
      bottom: 5vh;

      ul.react-tabs__tab-list {
        position: absolute;
        bottom: -$tabListMargin;
      }
    }

    .react-tabs__tab {
      border-top: none;
      border-bottom: $tabBorder;
    }

    .tab-panel.react-tabs__tab-panel--selected {
      padding-bottom: $panelPadding + $tabListHeight;
    }
  }
}



